import { sortBy } from 'lodash';
import React, {
  useEffect, useState, useContext, createContext,
} from 'react';

import { getFulfillers } from '../services/fulfillers';
import { Fulfiller } from '../types/types';
import useSnackbar from './SnackbarContext';

const FulfillersContext = createContext<{ fulfillers: Fulfiller[], isLoading: boolean }>({
  fulfillers: [],
  isLoading: false,
});

export const FulfillersProvider: React.FC = ({ children }) => {
  const [fulfillers, setFulfillers] = useState<Fulfiller[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { renderSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchFulfillers = async () => {
      try {
        setIsLoading(true);
        const newFulfillers = await getFulfillers();
        setFulfillers(sortBy(newFulfillers, (fulfiller) => fulfiller.name));
      } catch (error) {
        renderSnackbar('App.FailedToLoadFulfillers', 'danger', true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFulfillers();
  }, []);

  return (
    <FulfillersContext.Provider value={{ fulfillers, isLoading }}>
      {children}
    </FulfillersContext.Provider>
  );
};

export default () => useContext(FulfillersContext);
