import ExtensibleError from './ExtensibleError';

export type ServiceHttpResponse = {
  ok: boolean;
  status: number;
  statusText: string;
  body?: any;
};

export class ServiceHttpResponseError extends ExtensibleError {
  response: ServiceHttpResponse;

  responseMessage: any;

  constructor(message: string, response: ServiceHttpResponse, additionalInfo = null) {
    super(message, additionalInfo);

    this.response = response;
    if (response && response.body) {
      if (typeof response.body === 'object') {
        this.responseMessage = response.body.message;
      } else {
        this.responseMessage = response.body;
      }
    }
  }

  toString() {
    return `${this.message} - ${this.response.status} (${this.response.statusText})\n\t${this.responseMessage || ''}`;
  }
}
