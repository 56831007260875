import _pick from 'lodash/pick';
import { ServiceHttpResponse, ServiceHttpResponseError } from './ServiceHttpResponseError';
import auth from '../auth';

type CallFetchParams = {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  body?: { [key: string]: any };
  additionalHeaders?: { [key: string]: any };
  giveSimpleResponse?: boolean;
};

type CallFetch = <T>(params: CallFetchParams) => Promise<T>;

type CheckResponse = (res: Response, serviceComment: string, isSimpleResponse: boolean) => Promise<any>;

type BuiltOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers: Headers;
  mode?: 'cors' | 'navigate' | 'no-cors' | 'same-origin';
  body?: string;
};

type BuildOptions = (
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: any,
  additionalHeaders?: any
) => BuiltOptions;

export const checkResponse: CheckResponse = async (res, serviceComment, isSimpleResponse) => {
  let body;
  if (res.ok) {
    if (res.status === 204) {
      return isSimpleResponse ? null : { response: res };
    } // Need the response here for etag
    body = await res.json();
    return isSimpleResponse ? body : { response: res, body };
  }

  const response: ServiceHttpResponse = _pick(res, ['status', 'statusText', 'ok']);
  response.body = await (/application\/json/.test(res.headers.get('content-type') ?? '') ? res.json() : res.text());
  throw new ServiceHttpResponseError(`Error fetching ${serviceComment}`, response);
};

export const buildOptions: BuildOptions = (method = 'GET', body, additionalHeaders) => {
  const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${auth.getAccessToken()}`,
    ...additionalHeaders,
  });

  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers.append('Content-Type', 'application/json');
  }

  let builtBody: string | undefined;
  if (body && typeof body !== 'string') {
    builtBody = JSON.stringify(body);
  }

  return {
    method,
    mode: 'cors',
    headers,
    body: builtBody,
  };
};

export const callFetch: CallFetch = async ({
  url,
  body,
  method = 'GET',
  additionalHeaders = {},
  giveSimpleResponse = true,
}) => {
  try {
    const options = buildOptions(method, body, additionalHeaders);

    const rawResponse = await fetch(url, options);
    return checkResponse(rawResponse, `${method} ${url}`, giveSimpleResponse);
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    throw error;
  }
};
