import React from 'react';
import {
  Draggable, DraggableProvided, DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { Subtract } from 'utility-types';
import { colors } from '@cimpress/react-components';
import { IconDragDrop } from '@cimpress-technology/react-streamline-icons';
import styled from 'styled-components';
import { merge } from 'lodash';

import { FlexItem } from './components/styled';

const WithDraggableWrapper = styled.div`
  display: flex;
  border: .5px solid ${colors.platinum};
  border-radius: 3px;
  margin-bottom: 10px;
  background: white;
  padding: ${({ isDragDisabled }) => (isDragDisabled ? '10px' : '10px 10px 10px 0')};
`;

const ClonePlaceholder = styled(WithDraggableWrapper)`
  ~ div {
    // This the transform property for adjacent draggables when the clone is added to the document
    // A custom style could be passed instead, using a mix of isDragging or isDropAnimating,
    // or isDraggingOver to indicate one item in the droppable is being dragged over
    // see https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/drop-animation.md
    transform: none !important;
  }
`;

const Grip = styled(IconDragDrop)`
  min-width: 19px;
  ${({ isDragDisabled }) => isDragDisabled && 'visibility: collapse'}
`;

export interface WithDraggableProps {
  index: number;
  draggableId: string;
  isDragDisabled?: boolean;
  showPlaceholderWhileDragging?: boolean;
  style?: React.CSSProperties;
}

export type InjectedDraggableProps = {
  snapshot: DraggableStateSnapshot;
  provided: DraggableProvided;
}

const withDraggable = <P extends InjectedDraggableProps>(WrappedComponent: React.ComponentType<P>) => (
  class DraggableWrapper extends React.Component<
    Subtract<P, InjectedDraggableProps> & WithDraggableProps
    > {
    render() {
      const {
        draggableId,
        index,
        isDragDisabled,
        showPlaceholderWhileDragging = false,
        style,
        ...restProps
      } = this.props;

      return (
        <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
            const wrappedProps: any = { ...restProps, snapshot, provided };

            // merge the passed style with the react-beautiful-dnd props avoid overwriting styling the library sets
            const withDraggableWrapperProps = merge({}, provided.draggableProps, provided.dragHandleProps, { style });
            return (
              <>
                <WithDraggableWrapper
                  ref={provided.innerRef}
                  {...withDraggableWrapperProps}
                  isDragDisabled={isDragDisabled}
                >
                  <Grip size="lg" isDragDisabled={isDragDisabled} />
                  <FlexItem flexGrow="1">
                    <WrappedComponent {...wrappedProps} />
                  </FlexItem>
                </WithDraggableWrapper>
                {snapshot.isDragging && showPlaceholderWhileDragging && (
                  <ClonePlaceholder>
                    <Grip size="lg" isDragDisabled={isDragDisabled} />
                    <FlexItem flexGrow="1">
                      <WrappedComponent {...wrappedProps} />
                    </FlexItem>
                  </ClonePlaceholder>
                )}
              </>
            );
          }}
        </Draggable>
      );
    }
  }
);

export default withDraggable;
