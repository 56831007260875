import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexBox, colors } from '@cimpress/react-components';
import { IconBin } from '@cimpress-technology/react-streamline-icons';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { Fulfiller, FulfillerHandler } from '../../../types/types';
import SectionWrapper from '../SectionWrapper';
import { Collapsible, FlexItem, DroppableArea } from '../../styled';
import Id from '../Id';
import { FULFILLER } from '../../../constants/droppableTypes';
import withDraggable, { InjectedDraggableProps } from '../../../withDraggable';

const Header = styled.div`
  position: sticky;
  top: 54px;
  background: inherit;
  z-index: 2;
  margin-left: 8px;
  margin-right: 8px;
  padding-bottom: 2px;
`;

type FulfillerInfoProps = {
  fulfiller: Fulfiller;
  onRemove: FulfillerHandler;
};

const DraggableFulfiller = withDraggable<FulfillerInfoProps & InjectedDraggableProps>(({ fulfiller, onRemove }) => (
  <FlexBox middle right marginX="l">
    <FlexItem flex="1">{fulfiller.name}</FlexItem>
    <FlexItem><Id id={fulfiller.fulfillerId} /></FlexItem>
    <IconBin
      onClick={() => onRemove(fulfiller.fulfillerId)}
      size="s"
      weight="fill"
      color={colors.ocean.base}
      style={{ cursor: 'pointer' }}
    />
  </FlexBox>
));

const SelectedFulfillers: React.FC<{
  droppableId: string,
  fulfillers: Fulfiller[],
  onRemove: FulfillerHandler
}> = ({ droppableId, fulfillers, onRemove }) => (
  <SectionWrapper
    title={<FormattedMessage id="Filters.Selected" />}
    instructions={fulfillers.length > 0 ? null : <FormattedMessage id="Filters.FulfillerSelectedHelperText" />}
    style={{ height: '400px' }}
  >
    <Header>
      <Collapsible isVisible={fulfillers.length}>
        <FlexBox right>
          {`${fulfillers.length} results`}
        </FlexBox>
      </Collapsible>
    </Header>

    <Droppable droppableId={droppableId} type={FULFILLER}>
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <div ref={provided.innerRef} data-testid="selected-fulfillers">
          <DroppableArea
            isVertical
            isDragging={snapshot.isDraggingOver}
            isEmpty={!fulfillers.length}
          >
            {fulfillers.map((fulfiller, index) => (
              <DraggableFulfiller
                draggableId={fulfiller.fulfillerId}
                fulfiller={fulfiller}
                index={index}
                isDragDisabled
                key={fulfiller.fulfillerId}
                onRemove={onRemove}
                style={{
                  alignItems: 'center',
                  marginBottom: '4px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                }}
              />
            ))}
            {provided.placeholder}
          </DroppableArea>
        </div>
      )}
    </Droppable>
  </SectionWrapper>
);

export default SelectedFulfillers;
