import React, { useState } from 'react';
import { colors } from '@cimpress/react-components';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useIntl, IntlShape, FormattedMessage } from 'react-intl';

import { FILTER_CATEGORIES } from '../../../constants/droppables';
import { PaddedContainer } from '../../styled';
import { Filter } from '../../../types/types';
import withDraggable, { InjectedDraggableProps } from '../../../withDraggable';
import filterCategoryRegistry from '../../../constants/filterCategoryRegistry';
import { TextFieldSearch } from '../../shared';

const LeftMenuContainer = styled(PaddedContainer)`
  background-color: white;
  min-width: 320px;
  padding-top: 0px;
  box-shadow: 1px 0px 4px ${colors.alloy};

  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  top: 208px;
  max-height: calc(100vh - 208px);
  min-height: calc(100vh - 208px);
  overflow: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
`;

const TextFieldContainer = styled.div`
  background-color: white;
  padding-top: 15px;
  padding-bottom: 1px;

  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  top: 0px;
`;

const FilterTitleText = styled.h6`
  color: ${colors.slate};
`;

const FilterContainer = styled.div`
  color: ${colors.ocean.base};
`;

type FilterMenuItemProps = InjectedDraggableProps & {
  filter: Filter;
  intl: IntlShape;
};

const DraggableFilterMenuItem = withDraggable(({ intl, filter }: FilterMenuItemProps) => (
  <FilterContainer>
    {intl.formatMessage({ id: filter.filterData.filterCategoryTitleId })}
  </FilterContainer>
));

export const LEFT_MENU_ITEMS: Filter[] = Object.entries(filterCategoryRegistry)
  .map(([filterCategory, registration]) => ({
    id: filterCategory,
    filterType: 'primordial',
    filterName: '',
    filterData: { ...registration },
  }));

const LeftMenu: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const intl = useIntl();
  return (
    <LeftMenuContainer>
      <TextFieldContainer>
        <TextFieldSearch
          placeholder={intl.formatMessage({ id: 'Editor.SearchLeftMenu' })}
          value={searchText}
          onChange={(e: any) => setSearchText(e.target.value)}
        />
      </TextFieldContainer>

      <FilterTitleText><FormattedMessage id="Editor.Filters" /></FilterTitleText>
      <Droppable droppableId={FILTER_CATEGORIES} isDropDisabled>
        {(provided: any) => (
          <div ref={provided.innerRef} data-testid="left-menu">
            {LEFT_MENU_ITEMS.map((filter, index) => (
              <DraggableFilterMenuItem
                key={filter.id}
                draggableId={filter.id}
                index={index}
                showPlaceholderWhileDragging
                filter={filter}
                intl={intl}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </LeftMenuContainer>
  );
};

export default LeftMenu;
