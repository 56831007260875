import { FlexBox } from '@cimpress/react-components';
import Toggle from '@cimpress/react-components/lib/Toggle';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type FilterConditionsTitleType = 'IF' | 'THEN';

type FilterConditionsTitleTypeValues = {
  message: string,
  alternateMessage: string,
};

const valuesMap: Record<FilterConditionsTitleType, FilterConditionsTitleTypeValues> = {
  IF: {
    message: 'Filters.IF',
    alternateMessage: 'Filters.IFNOT',
  },
  THEN: {
    message: 'Filters.THEN',
    alternateMessage: 'Filters.DONOT',
  },
};

type OnModeChangeHandler = (isAlternateMode: boolean) => void;

interface Props {
  type: FilterConditionsTitleType;
  message: React.ReactNode;
  isAlternateMode?: boolean;
  onModeChange: OnModeChangeHandler;
}

const FilterConditionsTitleBar: React.FC<Props> = ({
  type, message, isAlternateMode = false, onModeChange,
}) => (
  <FlexBox middle>
    <div style={{ fontWeight: 'bold', marginRight: '8px' }}>
      <FormattedMessage
        id={isAlternateMode ? valuesMap[type].alternateMessage : valuesMap[type].message}
        values={{ message }}
      />
    </div>
    <Toggle size="sm" on={!isAlternateMode} onClick={() => onModeChange(!isAlternateMode)} />
  </FlexBox>
);

export default FilterConditionsTitleBar;
