import React, { useState } from 'react';
import {
  Modal, FlexBox, colors, Button,
} from '@cimpress/react-components';
import { IconBin } from '@cimpress-technology/react-streamline-icons';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import useSnackbar from '../../../context/SnackbarContext';
import { useDeleteRoutingConfiguration } from '../../../hooks/useRoutingConfigurationApi';
import { RoutingConfiguration } from '../../../types/types';
import { FlexItem } from '../../styled';

const ModalFooter = styled(FlexItem)`
  .modal-button {
    min-width: 120px;
    min-height: 40px;
   }
`;

interface DeleteConfigurationButtonProps {
  routingConfiguration: RoutingConfiguration;
  onDelete: Function;
}

const DeleteConfigurationButton = ({ routingConfiguration, onDelete }: DeleteConfigurationButtonProps) => {
  const { id, routeName } = routingConfiguration;

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false);
  const { isDeletingConfiguration, deleteConfiguration } = useDeleteRoutingConfiguration(id);

  const { renderSnackbar } = useSnackbar();

  const onDeleteConfiguration = async () => {
    const deleted = await deleteConfiguration();
    setIsConfirmationModalVisible(false);
    if (deleted) {
      onDelete(id);
      renderSnackbar('Routes.DeleteConfigurationSucceeded', 'success');
    } else {
      renderSnackbar('Routes.FailedToDeleteConfiguration', 'danger', true);
    }
  };

  return (
    <>
      <IconBin
        color={colors.ocean.base}
        weight="fill"
        onClick={() => setIsConfirmationModalVisible(true)}
        style={{ cursor: 'pointer' }}
        data-testid="delete-clickable"
      />
      <Modal
        show={isConfirmationModalVisible}
        title={<FormattedMessage id="Routes.DeleteConfiguration" />}
        footer={(
          <ModalFooter right marginX="s">
            <Button
              type="primary"
              className="modal-button"
              onClick={onDeleteConfiguration}
              disabled={isDeletingConfiguration}
            >
              {isDeletingConfiguration
                ? <FormattedMessage id="Routes.Deleting" />
                : <FormattedMessage id="Routes.Ok" />}
            </Button>
            <Button className="modal-button" onClick={() => setIsConfirmationModalVisible(false)}>
              <FormattedMessage id="Routes.Cancel" />
            </Button>
          </ModalFooter>
        )}
      >
        <FlexBox center>
          <FormattedMessage
            id="Routes.DeleteConfigurationConfirmation"
            values={{ identifier: routeName || id }}
          />
        </FlexBox>
      </Modal>
    </>
  );
};

export default DeleteConfigurationButton;
