import React from 'react';
import { colors, Button, FlexBox } from '@cimpress/react-components';
import { IconBin } from '@cimpress-technology/react-streamline-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import CountrySelector from './CountrySelector';
import RegionSelector from './RegionSelector';
import { FlexItem } from '../../../../styled';
import {
  FilterComponentProps, Geocode, GeocodeFilterData, SelectOption,
} from '../../../../../types/types';
import SectionWrapper from '../../../../shared/SectionWrapper';
import FilterConditionsTitleBar from '../../../../shared/FilterConditionsTitleBar';

const SelectWrapper = styled(FlexItem)`
  .form-group label {
    z-index: 1;
  }

  .form-group.form-group-active label {
    z-index: 1;
  }
  
  .form-group {
    margin-bottom: 0px;
  }

  width: 200px;
`;

const DeleteIcon = styled(IconBin)`
  cursor: pointer;
`;

const GeocodeSelector: React.FC<FilterComponentProps<GeocodeFilterData>> = ({ filter, onUpdate }) => {
  const { geocodes = [{ country: undefined, region: null }], useShippingLocale = true }:
    { geocodes: Geocode[], useShippingLocale: boolean } = filter.filterData;

  const setGeocodes = (newGeocodes: Geocode[]) => {
    const { filterData } = filter;
    const newFilter = { ...filter, filterData: { ...filterData, geocodes: newGeocodes } };
    onUpdate(newFilter);
  };

  const addGeocode = () => setGeocodes([...geocodes, { country: undefined, region: null }]);

  const setCountry = (country: SelectOption, index: number) => {
    const clonedGeocodes = [...geocodes];
    clonedGeocodes[index].country = country;
    clonedGeocodes[index].region = null;
    setGeocodes(clonedGeocodes);
  };

  const setRegion = (region: SelectOption | null, index: number) => {
    const clonedGeocodes = [...geocodes];
    clonedGeocodes[index].region = region;
    setGeocodes(clonedGeocodes);
  };

  const deleteGeocode = (indexToRemove: number) => setGeocodes(
    [...geocodes.slice(0, indexToRemove), ...geocodes.slice(indexToRemove + 1)],
  );

  const changeMode = (isAlternateMode: boolean) => onUpdate(
    { ...filter, filterData: { ...filter.filterData, useShippingLocale: !isAlternateMode } },
  );

  return (
    <div id="dropdown-portal">
      <SectionWrapper style={{ marginBottom: '15px' }}>
        <FilterConditionsTitleBar
          type="IF"
          message={<FormattedMessage id="Filters.Geocode.ShippingLocale" />}
          isAlternateMode={!useShippingLocale}
          onModeChange={changeMode}
        />

        <FlexBox isVertical style={{ marginTop: '15px' }}>
          {geocodes.map(({ country, region }, index) => (
            <div key={((country?.label || '') + (region?.label || '')) || index}>
              <FlexBox middle style={{ marginBottom: '15px' }}>

                <div className="h7" style={{ width: '15%', textAlign: 'center' }}>
                  <FormattedMessage id="Filters.Geocode.Location" values={{ index: index + 1 }} />
                </div>

                <FlexItem flexGrow="10">
                  <FlexBox marginX="l">
                    <SelectWrapper flexGrow="1">
                      <CountrySelector
                        label={<FormattedMessage id="Filters.Geocode.SelectCountry" />}
                        isClearable
                        country={country}
                        setCountry={(selectedCountry: SelectOption) => setCountry(selectedCountry, index)}
                        menuPortalTarget={document.body}
                      />
                    </SelectWrapper>

                    <SelectWrapper flexGrow="1">
                      <RegionSelector
                        label={<FormattedMessage id="Filters.Geocode.SelectRegion" />}
                        isClearable
                        country={country}
                        region={region}
                        setRegion={((selectedRegion: SelectOption) => setRegion(selectedRegion, index))}
                        menuPortalTarget={document.body}
                      />
                    </SelectWrapper>
                  </FlexBox>
                </FlexItem>

                <DeleteIcon
                  onClick={() => {
                    if (geocodes.length === 1) {
                      setGeocodes([{ country: undefined, region: null }]);
                    } else {
                      deleteGeocode(index);
                    }
                  }}
                  size="lg"
                  weight="fill"
                  color={colors.ocean.base}
                  style={{ width: '10%' }}
                />

              </FlexBox>
            </div>
          ))}
        </FlexBox>

        <FlexBox right>
          {/* commented out as the button is non-functional */}
          {/* <Button style={{ marginRight: '10px' }}>
            <FormattedMessage id="Filters.Geocode.AddLocationsFromList" />
          </Button> */}
          <Button type="primary" onClick={addGeocode}><FormattedMessage id="Filters.Geocode.AddLocation" /></Button>
          <div style={{ width: '10%' }} />
        </FlexBox>
      </SectionWrapper>
    </div>
  );
};

export default GeocodeSelector;
