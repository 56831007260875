import React from 'react';
import { shapes, FlexBox } from '@cimpress/react-components';
import useErrorBoundary from 'use-error-boundary';
import { FormattedMessage } from 'react-intl';

export const RobotError: React.FC<{ error: Error }> = ({ error }) => (
  <FlexBox isVertical middle>
    <shapes.Robot bsStyle="danger" />
    <h3><FormattedMessage id="UnexpectedErrorTitle" /></h3>
    <FormattedMessage id="UnexpectedErrorMessage" />
    <div>{error.message}</div>
  </FlexBox>
);

export const ErrorHandler: React.FC = ({ children }) => {
  const {
    ErrorBoundary,
    didCatch,
    error,
  } = useErrorBoundary();

  if (didCatch) {
    return (
      <RobotError error={error} />
    );
  }

  return (
    <ErrorBoundary>
      { children}
    </ErrorBoundary>
  );
};
