import { DropResult } from 'react-beautiful-dnd';

import * as Droppables from '../../constants/droppables';
import { EditorActions } from '../../context/EditorContext';
import { EditorState } from '../../reducers/editor';
import { parseAggregateDroppableId } from '../droppableIdHelper';
import BaseHandler from './BaseHandler';
import GeocodeFilterHandler from './GeocodeFilterHandler';
import WorkspaceFiltersHandler from './WorkspaceFiltersHandler';

interface StaticHandlerFactory {
  getDragEndHandler(
    editorState: EditorState,
    actions: EditorActions,
    dropResult: DropResult,
  ): BaseHandler | null,
}

const DragEventHandlerFactory: StaticHandlerFactory = class {
  // NOTE: As of now, we only support dragEnd events. If we need to start handling dragStart or dragUpdate,
  // we can add more static methods here (e.g. getDragStartHandler) for getting handlers based on drag event type.

  public static getDragEndHandler(
    editorState: EditorState,
    actions: EditorActions,
    dropResult: DropResult,
  ): BaseHandler | null {
    // Processing a dragEnd event is the responsibility of the destination droppable.
    // If we have no destination, we can't generate a dragEndHandler.
    if (!dropResult.destination) {
      return null;
    }

    const { id: droppableId } = parseAggregateDroppableId(dropResult.destination.droppableId);
    switch (droppableId) {
      case Droppables.WORKSPACE_FILTERS:
        return new WorkspaceFiltersHandler(editorState, actions, dropResult);
      case Droppables.GEOCODE_SELECTED_FILTERS:
        return new GeocodeFilterHandler(editorState, actions, dropResult);
      default:
        return null;
    }
  }
};

export default DragEventHandlerFactory;
