import pickBy from 'lodash/pickBy';

import { RoutingFilterRegistry, FilterCategory } from '../types/types';
import routingFilterRegistry from './routingFilterRegistry';

type FilterCategoryRegistration = {
  filterCategoryTitleId: string;
  members: RoutingFilterRegistry;
};

const getMembers = (filterCategory: FilterCategory): RoutingFilterRegistry => pickBy(
  routingFilterRegistry,
  (registration) => registration.filterCategory === filterCategory,
) as RoutingFilterRegistry;

const filterCategoryRegistry: Record<FilterCategory, FilterCategoryRegistration> = {
  [FilterCategory.FULFILLER]: {
    filterCategoryTitleId: 'Filters.FulfillerTitle',
    members: getMembers(FilterCategory.FULFILLER),
  },
  [FilterCategory.ORDER_ATTRIBUTE]: {
    filterCategoryTitleId: 'Filters.OrderAttributeTitle',
    members: getMembers(FilterCategory.ORDER_ATTRIBUTE),
  },
  [FilterCategory.PRODUCT_ATTRIBUTE]: {
    filterCategoryTitleId: 'Filters.ProductAttributeTitle',
    members: getMembers(FilterCategory.PRODUCT_ATTRIBUTE),
  },
};

export default filterCategoryRegistry;
