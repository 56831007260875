import { RoutingFilterRegistry, FilterCategory } from '../types/types';
import Primordial from '../components/pages/editor/filters/Primordial';
import Geocode from '../components/pages/editor/filters/geocode/Geocode';
import Capacity from '../components/pages/editor/filters/Capacity';

const routingFilterRegistry: RoutingFilterRegistry = {
  primordial: {
    Component: Primordial,
    filterTitleId: 'Filters.PrimordialTitle',
    supportsSummaryView: false,
  },
  geocode: {
    Component: Geocode,
    filterTitleId: 'Filters.Geocode.Title',
    supportsSummaryView: true,
    filterCategory: FilterCategory.ORDER_ATTRIBUTE,
  },
  capacity: {
    Component: Capacity,
    filterTitleId: 'Filters.CapacityTitle',
    supportsSummaryView: true,
    filterCategory: FilterCategory.ORDER_ATTRIBUTE,
  },
};

export default routingFilterRegistry;
