import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = '0j164qvqGlwgttZaGuEgcbcGD3WUa0Jx';

const auth = new CentralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
});

export default auth;
