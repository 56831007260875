import { FlexBox, colors } from '@cimpress/react-components';
import styled from 'styled-components';

const DroppableArea = styled(FlexBox)`
  border-radius: 3px;
  background-color: ${({ isDragging }) => (isDragging ? 'rgba(0, 136, 169, 0.05)' : '')};
  padding: 8px;
  border: ${({ isEmpty }) => (isEmpty ? `2px dashed ${colors.ocean.base}` : '')};
  min-height: 64px;
`;

export default DroppableArea;
