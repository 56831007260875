import React from 'react';
import { Link } from 'react-router-dom';
import { IconPencilAlt } from '@cimpress-technology/react-streamline-icons';

type RoutingConfigurationEditButtonProps = {
  id: string;
};

const RoutingConfigurationEditButton: React.FC<RoutingConfigurationEditButtonProps> = ({ id }) => (
  <Link to={`/editor/${id}`} data-testid="edit-clickable">
    <IconPencilAlt weight="fill" />
  </Link>
);

export default RoutingConfigurationEditButton;
