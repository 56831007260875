import { useState, useEffect, useCallback } from 'react';

import { RoutingConfiguration } from '../types/types';
import useSnackbar from '../context/SnackbarContext';
import * as routingConfigurationApi from '../services/routingConfiguration';

export const useGetRoutingConfigurations = () => {
  const [routingConfigurations, setRoutingConfigurations] = useState<RoutingConfiguration[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { renderSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const configurations = await routingConfigurationApi.getRoutingConfigurations();
        setRoutingConfigurations(configurations);
      } catch (e) {
        console.error(e.message);
        renderSnackbar('Routes.FailedToLoadConfigurations', 'danger', true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return { routingConfigurations, isLoading, setRoutingConfigurations };
};

export const useDeleteRoutingConfiguration = (id?: string) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const deleteConfiguration = useCallback(async (): Promise<boolean> => {
    if (!id) {
      return false;
    }

    setIsDeleting(true);
    try {
      await routingConfigurationApi.deleteRoutingConfiguration(id);
      return true;
    } catch (e) {
      console.error(e.message);
      return false;
    } finally {
      setIsDeleting(false);
    }
  }, [id]);

  return { isDeletingConfiguration: isDeleting, deleteConfiguration };
};

export const useGetRoutingConfiguration = (id?: string) => {
  const [routingConfiguration, setRoutingConfiguration] = useState<RoutingConfiguration>();
  const [isLoading, setIsLoading] = useState(!!id);

  const { renderSnackbar } = useSnackbar();

  const getRoutingConfiguration = useCallback(async () => {
    if (!id) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await routingConfigurationApi.getRoutingConfiguration(id);
      setRoutingConfiguration(result);
    } catch (error) {
      console.error(error);
      renderSnackbar('Editor.FailedToLoadConfiguration', 'danger', true);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getRoutingConfiguration();
  }, [id]);

  return { routingConfiguration, isLoading };
};

export const useSaveRoutingConfiguration = () => {
  const [isSaving, setIsSaving] = useState(false);

  const saveRoutingConfiguration = useCallback(async (routingConfiguration: RoutingConfiguration) => {
    setIsSaving(true);

    let result;

    try {
      if (routingConfiguration.id) {
        result = await routingConfigurationApi.putRoutingConfiguration(routingConfiguration);
      } else {
        result = await routingConfigurationApi.postRoutingConfiguration(routingConfiguration);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }

    return result;
  }, []);

  return { isSavingRoutingConfiguration: isSaving, saveRoutingConfiguration };
};
