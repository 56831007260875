import React, { useMemo } from 'react';
import { Select } from '@cimpress/react-components';
import CountryRegionData from 'country-region-data/data.json';

import { SelectOption } from '../../../../../types/types';

export type RegionSelectorProps = {
  label: React.ReactNode;
  country?: SelectOption;
  region: SelectOption | null;
  setRegion(region: SelectOption | null): void;
  // allow pass-through props
  [propName: string]: any;
};

const getRegions = (country?: SelectOption) => {
  const regions = CountryRegionData.find(
    ({ countryName }: { countryName: string }) => country?.value === countryName,
  )?.regions || [];
  return regions.map(({ name }) => ({ label: name, value: name }));
};

const RegionSelector: React.FC<RegionSelectorProps> = ({
  country, region, setRegion, label, ...props
}) => (
  <Select
    {...props}
    label={label}
    options={useMemo(() => getRegions(country), [country])}
    value={region}
    onChange={setRegion}
    tether
    styles={{
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
      }),
    }}
  />
);

export default RegionSelector;
