import React, { useContext, useReducer, Dispatch } from 'react';
import { Snackbar } from '@cimpress/react-components';
import { FormattedMessage } from 'react-intl';
import { RenderSnackbarHandler, SnackbarStyle } from '../types/types';

type SnackbarState = {
  isVisible: boolean;
  style: SnackbarStyle;
  intlId: string;
  shouldManuallyDismiss: boolean;
};

const initialState: SnackbarState = {
  isVisible: false,
  style: 'info',
  intlId: '',
  shouldManuallyDismiss: false,
};

type Action =
  | {
    type: 'HIDE_SNACKBAR',
  }
  | {
    type: 'SHOW_SNACKBAR',
    payload: {
      style: SnackbarStyle;
      intlId: string;
      shouldManuallyDismiss: boolean;
    }
  };

const reducer = (state: SnackbarState, action: Action) => {
  switch (action.type) {
    case 'HIDE_SNACKBAR':
      return {
        ...state,
        isVisible: false,
      };
    case 'SHOW_SNACKBAR':
      return {
        ...state,
        ...action.payload,
        isVisible: true,
      };
    default:
      return state;
  }
};

const SnackbarContext = React.createContext<{
  snackbarDispatch: Dispatch<Action>;
}>({
  snackbarDispatch: () => null,
});

export default () => {
  const { snackbarDispatch } = useContext(SnackbarContext);
  const renderSnackbar: RenderSnackbarHandler = (intlId, style, shouldManuallyDismiss = false) => snackbarDispatch({
    type: 'SHOW_SNACKBAR',
    payload: {
      style,
      intlId,
      shouldManuallyDismiss,
    },
  });

  const hideSnackbar = () => snackbarDispatch({ type: 'HIDE_SNACKBAR' });

  return {
    renderSnackbar,
    hideSnackbar,
  };
};

export const SnackbarProvider: React.FC = ({ children }) => {
  const [snackbarState, snackbarDispatch] = useReducer(reducer, initialState);
  return (
    <SnackbarContext.Provider value={{ snackbarDispatch }}>
      <Snackbar
        bsStyle={snackbarState.style}
        delay={snackbarState.shouldManuallyDismiss ? undefined : 1500}
        onHideSnackbar={() => snackbarDispatch({ type: 'HIDE_SNACKBAR' })}
        show={snackbarState.isVisible && Boolean(snackbarState.intlId)}
      >
        {snackbarState.intlId && <FormattedMessage id={snackbarState.intlId} />}
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};
