// this regex pattern contains two capture groups and will match against strings like:
// "WORKSPACE_FILTERS" and/or "-arbitraryCustomString"
const regEx = new RegExp(/([A-Z_]+)(?:-?(.+))?/i);

export const constructAggregateDroppableId = (id: string, customString: string) => `${id}-${customString}`;

export const parseAggregateDroppableId = (id: string) => {
  const result = id.match(regEx);

  if (!result) {
    throw new Error(`Unable to parse aggregate id ${id}`);
  }

  const [, parsedId, customString] = result;
  return {
    id: parsedId,
    customString,
  };
};
