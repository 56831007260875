import React from 'react';
import styled from 'styled-components';
import { colors } from '@cimpress/react-components';
import { PaddedContainer } from '../styled';

const SectionBorder = styled(PaddedContainer)`
  background-color: ${colors.white};
  border: .5px solid ${colors.platinum};
  border-radius: 3px;
  padding: 0px 18px 18px 18px;
  flex: 1;
  position: relative;
  overflow-x: hidden; 
  overflow-y: auto;
  cursor: default;
  ${({ height }) => (height && `height: ${height}px;`)}
`;

const MockPadding = styled.div`
  height: 18px;
  background: inherit;
  position: sticky;
  top: 0px;
  z-index: 2;
`;

const SectionTitle = styled.div`
  background: inherit;
  font-weight: bold;
  padding-bottom: 18px;
  position: sticky;
  top: 18px;
  z-index: 2;
`;

const SectionBackground = styled.div`
  background: inherit;
  position: absolute;
  color: ${colors.slate};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const SectionWrapper: React.FC<{
  title?: React.ReactNode,
  instructions?: React.ReactNode,
  style?: React.CSSProperties;
}> = ({
  children, title, instructions, style,
}) => (
  <SectionBorder style={style}>
    {instructions ? <SectionBackground>{instructions}</SectionBackground> : null}
    <MockPadding />
    {title ? <SectionTitle>{title}</SectionTitle> : null}
    {children}
  </SectionBorder>
);

export default SectionWrapper;
