import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorHandler } from '../../shared/ErrorHandler';
import {
  FullHeightContainer,
  PaddedContainer,
  PageContainer,
} from '../../styled';

const Products: React.FC = () => (
  <ErrorHandler>
    <PageContainer>
      <FullHeightContainer backgroundColor="white">
        <PaddedContainer>
          <div>
            <h1><FormattedMessage id="Products.PageTitle" /></h1>
          </div>
        </PaddedContainer>
      </FullHeightContainer>
    </PageContainer>
  </ErrorHandler>
);

export default Products;
