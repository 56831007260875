import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, FlexBox } from '@cimpress/react-components';
import { IconAddCircle } from '@cimpress-technology/react-streamline-icons';

const CreateNewRouteButton: React.FC = () => (
  <Link to="/editor">
    <Button type="primary">
      <FlexBox middle marginX="s">
        <IconAddCircle weight="fill" />
        <span><FormattedMessage id="Routes.CreateNewRoute" /></span>
      </FlexBox>
    </Button>
  </Link>
);

export default CreateNewRouteButton;
