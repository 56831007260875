import React, { useEffect, useState } from 'react';
import { FourOhFour } from '@cimpress/react-components';
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { createBrowserHistory } from 'history';

import auth from './auth';
import Shell from './Shell';
import {
  Products, Routes, Editor,
} from './components/pages';
import Loading from './components/shared/Loading';
import { SnackbarProvider } from './context/SnackbarContext';
import translations from './i18n/locales';
import { SettingsProvider, useInitializeSettings } from './hooks/useSettings';
import { FulfillersProvider } from './context/FulfillersContext';

auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));
const basename = process.env.REACT_APP_ROUTER_BASENAME || '';
const history = createBrowserHistory({ basename });

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(!auth.isLoggedIn());
  const [authenticationError, setAuthenticationError] = useState<Error | undefined>(undefined);

  const { settings } = useInitializeSettings(isAuthenticating);
  const { language } = settings;

  useEffect(() => {
    const authenticate = async () => {
      if (!auth.isLoggedIn()) {
        setIsAuthenticating(true);
      }

      try {
        await auth.ensureAuthentication({
          nextUri: window.location.pathname + window.location.search,
        });
        setIsAuthenticating(false);
      } catch (err) {
        setAuthenticationError(err);
      }
    };
    authenticate();
  }, []);

  if (isAuthenticating) {
    return <Loading />;
  }

  return auth.isLoggedIn() ? (
    <IntlProvider locale={language} key={language} messages={translations[language]} defaultLocale="en">
      <SnackbarProvider>
        <SettingsProvider value={{ settings }}>
          <FulfillersProvider>
            <Router history={history}>
              <Shell>
                <Switch>
                  <Route exact path="/(index.html)?"><Redirect to="/routes" /></Route>
                  <Route path="/products" component={Products} />
                  <Route path="/routes" component={Routes} />
                  <Route path="/editor/:id" component={Editor} />
                  <Route path="/editor" component={Editor} />
                  <Route component={FourOhFour} />
                </Switch>
              </Shell>
            </Router>
          </FulfillersProvider>
        </SettingsProvider>
      </SnackbarProvider>
    </IntlProvider>
  ) : (
    <div>
      <FormattedMessage id="Login.AuthenticationFailed" />
      {authenticationError && authenticationError.message}
    </div>
  );
}

export default App;
