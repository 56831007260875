import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import routingFilterRegistry from '../constants/routingFilterRegistry';
import { Filter } from '../types/types';

export default (filter: Filter) => {
  const intl = useIntl();
  const filterRegistration = routingFilterRegistry[filter.filterType];

  const constructFilterTitle = () => {
    const { filterName, filterType } = filter;
    const { filterTitleId } = filterRegistration;

    if (!isEmpty(filterName)) {
      return filterName;
    }

    if (filterType === 'primordial') {
      const { filterData: { filterCategoryTitleId } } = filter;

      return intl.formatMessage(
        { id: filterTitleId },
        { filterCategoryTitle: intl.formatMessage({ id: filterCategoryTitleId }) },
      );
    }

    return intl.formatMessage({ id: filterTitleId });
  };

  return {
    ...filterRegistration,
    filterTitle: constructFilterTitle(),
  };
};
