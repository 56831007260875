import React, { useState } from 'react';
import { IconBin, IconArrowThickDown } from '@cimpress-technology/react-streamline-icons';
import { Accordion, colors, FlexBox } from '@cimpress/react-components';
import styled from 'styled-components';

import withDraggable, { InjectedDraggableProps } from '../../../../withDraggable';
import useRoutingFilterRegistry from '../../../../hooks/useRoutingFilterRegistry';
import { Filter, FilterComponentProps } from '../../../../types/types';
import useEditor from '../../../../context/EditorContext';
import { FlexItem } from '../../../styled';

const RoutingFilterTitle = styled.h3`
  margin-top: 0;
`;

const PaddedWrapper = styled.div`
  padding: 8px 48px 10px 30px;
`;

type TitleBarProps = {
  filterTitle: string;
  onRemove(): void;
};

type FilterWrapperProps = {
  filterTitle: string;
  filter: Filter;
  onUpdate(newFilter: Filter): void;
  onRemove(): void;
  Component: React.ComponentType<FilterComponentProps>;
};

const TitleBar: React.FC<TitleBarProps> = ({ filterTitle, onRemove }) => (
  <>
    <FlexItem flexGrow="1">
      <RoutingFilterTitle>{filterTitle}</RoutingFilterTitle>
    </FlexItem>
    <IconBin
      onClick={onRemove}
      size="lg"
      weight="fill"
      color={colors.ocean.base}
      style={{ cursor: 'pointer' }}
      data-testid="filter-delete"
    />
  </>
);

const NoSummaryFilter: React.FC<FilterWrapperProps> = ({
  filterTitle,
  filter,
  onUpdate,
  onRemove,
  Component,
}) => (
  <>
    <FlexBox style={{ paddingLeft: '30px', paddingTop: '2px' }}>
      <TitleBar filterTitle={filterTitle} onRemove={onRemove} />
    </FlexBox>
    <PaddedWrapper>
      <Component filter={filter} readOnly={false} onUpdate={onUpdate} />
    </PaddedWrapper>
  </>
);

const CollapsibleFilter: React.FC<FilterWrapperProps> = ({
  filterTitle,
  filter,
  onUpdate,
  onRemove,
  Component,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Accordion
        title={<TitleBar filterTitle={filterTitle} onRemove={onRemove} />}
        style={{ paddingTop: '2px', paddingBottom: '0', marginBottom: '0' }}
        headerStyle={{ display: 'flex', padding: '0' }}
        bodyStyle={{ paddingLeft: '30px', paddingBottom: '10px' }}
        variant="minimal"
        onHeaderClick={(event: any, isNowOpen: boolean) => setIsOpen(isNowOpen)}
      >
        <Component filter={filter} readOnly={false} onUpdate={onUpdate} />
      </Accordion>
      {isOpen ? null : (
        <PaddedWrapper data-testid="readonlyComponent">
          <Component filter={filter} onUpdate={onUpdate} readOnly />
        </PaddedWrapper>
      )}
    </>
  );
};

export type RoutingFilterProps = {
  filter: Filter;
  style?: React.CSSProperties;
};

const RoutingFilter: React.FC<RoutingFilterProps & InjectedDraggableProps> = ({ filter }) => {
  const { actions: { updateFilter, removeFilter } } = useEditor();
  const { Component, filterTitle, supportsSummaryView } = useRoutingFilterRegistry(filter);
  const onUpdate = (newFilter: Filter) => updateFilter(newFilter);
  const onRemove = () => removeFilter(filter.id);
  const filterProps = {
    filterTitle,
    filter,
    onUpdate,
    onRemove,
    Component,
  };
  const FilterWrapper = supportsSummaryView ? CollapsibleFilter : NoSummaryFilter;

  return (
    <div data-testid="routing-filter">
      <FilterWrapper {...filterProps} />
      <IconArrowThickDown
        color={colors.alloy}
        weight="fill"
        size="lg"
        style={{ display: 'block', margin: '20px auto -35px auto' }}
      />
    </div>
  );
};

export default withDraggable(RoutingFilter);
