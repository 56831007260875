import {
  useEffect, useState, useContext, createContext,
} from 'react';
import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient';

import auth from '../auth';
import { LANGUAGES, DEFAULT_LANGUAGE } from '../constants/languages';

export type Settings = {
  language: string;
};
const DEFAULT_SETTINGS: Settings = { language: DEFAULT_LANGUAGE };

export const useInitializeSettings = (isAuthenticating: boolean) => {
  const [settings, setSettings] = useState<Settings>(DEFAULT_SETTINGS);

  useEffect(() => {
    const fetchSettings = async () => {
      if (!isAuthenticating) {
        try {
          const loadedSettings = await getSettings(auth.getAccessToken(), LANGUAGES);
          const newSettings = loadedSettings.global || DEFAULT_SETTINGS;

          setSettings(newSettings);
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchSettings();
  }, [isAuthenticating]);

  return { settings };
};

const SettingsContext = createContext({ settings: DEFAULT_SETTINGS });
export const SettingsProvider = SettingsContext.Provider;

const useSettings = () => useContext(SettingsContext);
export default useSettings;
