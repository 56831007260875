import { DraggableLocation, DropResult } from 'react-beautiful-dnd';

import { EditorActions } from '../../context/EditorContext';
import { EditorState } from '../../reducers/editor';

export default abstract class BaseHandler {
  editorState: EditorState;

  actions: EditorActions;

  source: DraggableLocation;

  destination?: DraggableLocation;

  constructor(
    editorState: EditorState,
    actions: EditorActions,
    dropResult: DropResult,
  ) {
    this.editorState = editorState;
    this.actions = actions;
    this.source = dropResult.source;
    this.destination = dropResult.destination;
  }

  public processDragEnd(): void {
    if (!this.destination) {
      return;
    }

    if (this.destination.droppableId === this.source.droppableId) {
      this.reorder();
    } else {
      this.move();
    }
  }

  abstract move(): void;

  abstract reorder(): void;
}
