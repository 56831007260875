import React from 'react';
import { TextField, colors } from '@cimpress/react-components';
import { IconSearch } from '@cimpress-technology/react-streamline-icons';
import styled from 'styled-components';

const TextFieldWrapper = styled(TextField)`
  .input-group-btn {
    width: 0;
  }
`;

const TextFieldSearchIcon = styled(IconSearch)`
  transform: translateY(-50%);
  z-index: 1000;
`;

type TextFieldSearchProps = {
  onChange?: (e: Event) => void,
  placeholder?: string,
  value?: string,
}

const TextFieldSearch: React.FC<TextFieldSearchProps> = ({ placeholder, value, onChange }) => (
  <TextFieldWrapper
    inputStyle={{ paddingRight: '45px' }}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    rightAddon={(
      <TextFieldSearchIcon color={colors.ocean.base} size="lg" style={{ right: '14px', position: 'absolute' }} />
    )}
  />
);

export default TextFieldSearch;
