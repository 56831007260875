import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexBox, colors } from '@cimpress/react-components';

const Id: React.FC<{ id: string; }> = ({ id }) => (
  <FlexBox middle marginX="s">
    <h6 style={{ color: colors.shale }}><FormattedMessage id="Id" /></h6>
    <div style={{ color: colors.alloy }}>{id}</div>
  </FlexBox>
);

export default Id;
