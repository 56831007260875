import styled from 'styled-components';

interface PaddedContainerProps {
  backgroundColor?: string;
}

const PaddedContainer = styled.div<PaddedContainerProps>`
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: ${({ backgroundColor }) => backgroundColor || ''};
`;

export default PaddedContainer;
