import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlexBox, colors } from '@cimpress/react-components';
import styled from 'styled-components';
import {
  debounce, filter, isEmpty, slice,
} from 'lodash';
import { Droppable } from 'react-beautiful-dnd';
import escapeStringRegexp from 'escape-string-regexp';

import { Fulfiller } from '../../../types/types';
import SectionWrapper from '../SectionWrapper';
import TextFieldSearch from '../TextFieldSearch';
import { FILTERED_FULFILLERS } from '../../../constants/droppables';
import { FULFILLER } from '../../../constants/droppableTypes';
import withDraggable, { InjectedDraggableProps } from '../../../withDraggable';
import { Collapsible, FlexItem, DroppableArea } from '../../styled';
import Id from '../Id';

const numFulfillersToDisplay = 20;

const Header = styled.div`
  position: sticky;
  top: 54px;
  background: inherit;
  z-index: 2;
  padding: 8px;
  padding-bottom: 2px;
`;

const MoreResultsText = styled.h6`
  text-align: center;
  color: ${colors.alloy};
  padding: 0px 8px;
`;

const DraggableFulfiller = withDraggable<{ fulfiller: Fulfiller } & InjectedDraggableProps>(({ fulfiller }) => (
  <FlexBox middle right marginX="l">
    <FlexItem flex="1">{fulfiller.name}</FlexItem>
    <FlexItem><Id id={fulfiller.fulfillerId} /></FlexItem>
  </FlexBox>
));

const FilterForMoreResults = () => (
  <MoreResultsText>
    <i><FormattedMessage id="Filters.DisplayMoreResults" /></i>
  </MoreResultsText>
);

const FilterFulfillers: React.FC<{ fulfillers: Fulfiller[] }> = ({ fulfillers }) => {
  const [filterText, setFilterText] = useState<string>('');
  const [matchingFulfillers, setMatchingFulfillers] = useState<Fulfiller[]>(fulfillers);

  const intl = useIntl();

  const performFilter = () => {
    const regExp = new RegExp(escapeStringRegexp(filterText), 'i');
    const fulfillerIsMatch = (fulfiller: Fulfiller) => isEmpty(filterText)
      || regExp.test(fulfiller.name)
      || regExp.test(fulfiller.fulfillerId);
    setMatchingFulfillers(filter(fulfillers, fulfillerIsMatch));
  };

  const delayedPerformFilter = useCallback(debounce(performFilter, 150), [fulfillers, filterText]);

  useEffect(() => {
    delayedPerformFilter();
    return delayedPerformFilter.cancel;
  }, [filterText, delayedPerformFilter]);

  // if the fulfillers change do an immediate filter of them
  useEffect(() => {
    performFilter();
  }, [fulfillers]);

  return (
    <SectionWrapper
      title={<FormattedMessage id="Filters.Filter" />}
      instructions={matchingFulfillers.length
        ? null
        : <FormattedMessage id="Filters.FulfillerFilterNoFulfillersText" />}
      style={{ height: '400px' }}
    >
      <Header>
        <TextFieldSearch
          placeholder={intl.formatMessage({ id: 'Filters.FulfillerFilterPlaceholder' })}
          value={filterText}
          onChange={(e: any) => setFilterText(e.target.value)}
        />
        <Collapsible isVisible={matchingFulfillers.length}>
          <FlexBox right>
            {`${matchingFulfillers.length} results`}
          </FlexBox>
        </Collapsible>
      </Header>
      <Droppable droppableId={FILTERED_FULFILLERS} type={FULFILLER} isDropDisabled>
        {(provided: any) => (
          <div ref={provided.innerRef} data-testid="filtered-fulfillers">
            <DroppableArea isVertical>
              {slice(matchingFulfillers, 0, numFulfillersToDisplay).map((fulfiller, index) => (
                <DraggableFulfiller
                  draggableId={fulfiller.fulfillerId}
                  fulfiller={fulfiller}
                  index={index}
                  key={fulfiller.fulfillerId}
                  style={{
                    alignItems: 'center',
                    marginBottom: '4px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  }}
                />
              ))}
              {provided.placeholder}
              {matchingFulfillers.length > numFulfillersToDisplay && <FilterForMoreResults />}
            </DroppableArea>
          </div>
        )}
      </Droppable>
    </SectionWrapper>
  );
};

export default FilterFulfillers;
