import { Filter, RoutingConfiguration } from '../types/types';

export const SET_ROUTE_NAME = 'SET_ROUTE_NAME';
export const TOGGLE_IS_ACTIVE = 'TOGGLE_IS_ACTIVE';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const REORDER_FILTERS = 'REORDER_FILTERS';
export const LOADED_NEW_ROUTING_CONFIGURATION = 'LOADED_NEW_ROUTING_CONFIGURATION';

export type EditorState = {
  isDirty: boolean;
  routingConfiguration: RoutingConfiguration;
};

export type Action =
  | {
    type: typeof TOGGLE_IS_ACTIVE,
  }
  | {
    type: typeof SET_ROUTE_NAME,
    value: string
  }
  | {
    type: typeof UPDATE_FILTER,
    value: Filter
  }
  | {
    type: typeof REMOVE_FILTER,
    value: string
  }
  | {
    type: typeof REORDER_FILTERS,
    value: Array<Filter>
  }
  | {
    type: typeof LOADED_NEW_ROUTING_CONFIGURATION,
    value: RoutingConfiguration
  };

const reducer = (state: EditorState, action: Action): EditorState => {
  switch (action.type) {
    case LOADED_NEW_ROUTING_CONFIGURATION:
      return {
        ...state,
        isDirty: false,
        routingConfiguration: action.value,
      };
    case REMOVE_FILTER:
      return {
        ...state,
        isDirty: true,
        routingConfiguration: {
          ...state.routingConfiguration,
          filters: state.routingConfiguration.filters.filter((filter) => filter.id !== action.value),
        },
      };
    case REORDER_FILTERS:
      return {
        ...state,
        isDirty: true,
        routingConfiguration: {
          ...state.routingConfiguration,
          filters: action.value,
        },
      };
    case SET_ROUTE_NAME:
      return {
        ...state,
        isDirty: true,
        routingConfiguration: {
          ...state.routingConfiguration,
          routeName: action.value,
        },
      };
    case UPDATE_FILTER:
      return {
        ...state,
        isDirty: true,
        routingConfiguration: {
          ...state.routingConfiguration,
          filters: state.routingConfiguration.filters.map((filter) => {
            const { value } = action;
            if (value.id === filter.id) {
              return value;
            }
            return filter;
          }),
        },
      };
    default:
      return state;
  }
};

export default reducer;
