import React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

import useEditor from '../../../context/EditorContext';
import RoutingFilter from './filters/RoutingFilter';
import { WORKSPACE_FILTERS } from '../../../constants/droppables';
import { ReactComponent as EmptyWorkspaceIcon } from '../../../icons/empty-workspace.svg';
import { DroppableArea } from '../../styled';

const Workspace: React.FC = () => {
  const { editorState: { routingConfiguration } } = useEditor();
  const isEditorEmpty = routingConfiguration.filters.length === 0;

  return (
    <Droppable droppableId={WORKSPACE_FILTERS}>
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <div ref={provided.innerRef} data-testid="workspace">
          <DroppableArea
            isVertical
            paddingY="s"
            middle={isEditorEmpty}
            isDragging={snapshot.isDraggingOver}
            isEmpty={isEditorEmpty}
          >
            {!isEditorEmpty ? (
              <>
                {routingConfiguration.filters.map((filter, index) => (
                  <RoutingFilter
                    filter={filter}
                    index={index}
                    key={filter.id}
                    draggableId={filter.id}
                    style={{ marginBottom: '30px' }}
                  />
                ))}
                {/* Only render the placeholder when the workspace isn't empty to keep
                the empty workspace from growing vertically */}
                {provided.placeholder}
              </>
            )
              : (
                <>
                  <EmptyWorkspaceIcon height="100px" width="100px" />
                  <h2><FormattedMessage id="Editor.EmptyWorkspaceMessage" /></h2>
                  <p><FormattedMessage id="Editor.EmptyWorkspaceMessageDetailed" /></p>
                </>
              )}
          </DroppableArea>
        </div>
      )}
    </Droppable>
  );
};

export default Workspace;
