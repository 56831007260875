import React from 'react';
import { shapes, FlexBox } from '@cimpress/react-components';

/*
  TODO: Remove the extra div below once the FlexBox component passes along other props.
  https://gitlab.com/Cimpress-Technology/internal-open-source/component-library/react-components/-/merge_requests/360
*/

const Loading = () => (
  <FlexBox center>
    <div data-testid="loadingSpinner">
      <shapes.Spinner />
    </div>
  </FlexBox>
);

export default Loading;
