import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexBox, Table, Tooltip } from '@cimpress/react-components';
import { reject } from 'lodash';

import { useGetRoutingConfigurations } from '../../../hooks/useRoutingConfigurationApi';
import { RoutingConfiguration } from '../../../types/types';
import Loading from '../../shared/Loading';
import RoutingConfigurationDeleteButton from './RoutingConfigurationDeleteButton';
import RoutingConfigurationEditButton from './RoutingConfigurationEditButton';
import CreateNewRouteButton from './CreateNewRouteButton';
import {
  Heading, HoverableText, PageContainer, InlineText, FullHeightContainer,
} from '../../styled';
import { ErrorHandler } from '../../shared/ErrorHandler';

const Routes: React.FC = () => {
  const { routingConfigurations, isLoading, setRoutingConfigurations } = useGetRoutingConfigurations();

  const onDeleteConfiguration = (id: string) => {
    const remainingConfigurations = reject(routingConfigurations, { id });
    setRoutingConfigurations(remainingConfigurations);
  };

  const [pageSize, setPageSize] = useState(10);
  const onPageSizeChange = (newPageSize: number) => setPageSize(newPageSize);

  const columns = [
    { Header: <FormattedMessage id="Routes.Route" />, accessor: 'route' },
    {
      Header: <FormattedMessage id="Routes.Products" />,
      accessor: 'products',
      Cell: ({ value: products }: { value: string[] }) => (
        <Tooltip contents={products.join(' ')}>
          <HoverableText>{products.length}</HoverableText>
        </Tooltip>
      ),
    },
    { Header: <FormattedMessage id="Routes.LastEdit" />, accessor: 'lastEdit' },
    { Header: <FormattedMessage id="Routes.Created" />, accessor: 'created' },
    { Header: <FormattedMessage id="Routes.Author" />, accessor: 'author' },
    {
      Header: <FormattedMessage id="Routes.Actions" />,
      accessor: 'actions',
      Cell: ({ value: { configuration } }: { value: { configuration: RoutingConfiguration } }) => (
        <FlexBox marginX="m">
          <RoutingConfigurationDeleteButton
            routingConfiguration={configuration}
            onDelete={onDeleteConfiguration}
          />
          {configuration.id && <RoutingConfigurationEditButton id={configuration.id} />}
        </FlexBox>
      ),
    },
  ];

  const renderConfiguration = (configuration: RoutingConfiguration) => (
    {
      route: configuration.routeName,
      created: configuration.createdAt,
      products: ['Product 1', 'Product 2', 'Product 3'], // TODO pull this from somewhere
      author: configuration.createdBy,
      actions: {
        configuration,
      },
    }
  );

  const totalRows = routingConfigurations.length;
  const totalRowsMessage = (
    <FormattedMessage
      id="Routes.TotalRoutesCount"
      values={{ visibleRows: Math.min(pageSize, totalRows), totalRows }}
    />
  );

  return (
    <ErrorHandler>
      <PageContainer>
        <FullHeightContainer backgroundColor="white">
          <FlexBox spaceBetween paddingX="xl">
            <FlexBox bottom>
              <Heading><FormattedMessage id="Routes.PageTitle" /></Heading>
              <InlineText>{totalRowsMessage}</InlineText>
              <FlexBox right>
                <CreateNewRouteButton />
              </FlexBox>
            </FlexBox>
          </FlexBox>
          {isLoading ? <Loading /> : (
            <FlexBox isVertical paddingX="xl" paddingY="m">
              <Table
                columns={columns}
                data={routingConfigurations.map(renderConfiguration)}
                onPageSizeChange={onPageSizeChange}
                minRows={totalRows ? 0 : 10}
              />
            </FlexBox>
          )}
        </FullHeightContainer>
      </PageContainer>
    </ErrorHandler>
  );
};

export default Routes;
