import { v4 as uuidv4 } from 'uuid';

import { RoutingConfiguration } from '../types/types';
import { routingConfigurations as mockRoutingConfigurations } from '../mockData';

export const getRoutingConfigurations = (): Promise<RoutingConfiguration[]> => Promise.resolve(
  mockRoutingConfigurations,
);

export const deleteRoutingConfiguration = (routingConfiguration: string): Promise<void> => new Promise(
  (resolve: any) => setTimeout(resolve, 2000, routingConfiguration),
);

export const getRoutingConfiguration = (id: string): Promise<RoutingConfiguration> => (
  Promise.resolve({
    id,
    routeName: 'FRaC Test Configuration',
    filters: [
      {
        filterType: 'geocode',
        filterName: '',
        filterData: {},
        id: uuidv4(),
      },
      {
        filterType: 'capacity',
        filterName: '',
        filterData: {},
        id: uuidv4(),
      },
    ],
    createdAt: '2020-10-16T17:15:46+00:00',
    createdBy: 'waad|36RrRTmmn85hylTfU8t12NY3_qzqTm4NTlfbko5mqTM',
  })
);

export const putRoutingConfiguration = (routingConfiguration: RoutingConfiguration): Promise<RoutingConfiguration> => (
  new Promise((resolve: any) => setTimeout(resolve, 2000, routingConfiguration))
);

export const postRoutingConfiguration = (routingConfiguration: RoutingConfiguration): Promise<RoutingConfiguration> => (
  new Promise((resolve: any) => setTimeout(resolve, 2000, { ...routingConfiguration, id: 'new-routing-configuration' }))
);
