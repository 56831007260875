import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Button, FlexBox, Toggle, InlineEdit, colors,
} from '@cimpress/react-components';
import { IconFloppyDiskAlt, IconInformationCircle } from '@cimpress-technology/react-streamline-icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { FlexItem, PaddedContainer } from '../../styled';
import useEditor from '../../../context/EditorContext';
import useSnackbar from '../../../context/SnackbarContext';
import { useSaveRoutingConfiguration } from '../../../hooks/useRoutingConfigurationApi';

const FlexItemWarning = styled(FlexBox)`
  color: ${colors.warning.darkest};
`;

const ToolbarContainer = styled(FlexBox)`
  background: inherit;
  position: sticky;
  position: -webkit-sticky;
  z-index: 5;
  top: 120px;
`;

const Toolbar: React.FC = () => {
  const {
    editorState: { routingConfiguration, isDirty },
    actions: { setRouteName, toggleActive, loadedNewRoutingConfiguration },
  } = useEditor();
  const { isSavingRoutingConfiguration, saveRoutingConfiguration } = useSaveRoutingConfiguration();
  const { renderSnackbar } = useSnackbar();
  const history = useHistory();
  const intl = useIntl();

  const saveRoute = async () => {
    const result = await saveRoutingConfiguration(routingConfiguration);

    if (result) {
      renderSnackbar('Editor.SavedRoutingConfiguration', 'success');

      // If the routing configuration now has an id, navigate to it's specific url.
      // Otherwise, just update the editorState with the latest version of the routing configuration.
      if (!routingConfiguration.id && result.id) {
        history.push(`/editor/${result.id}`);
      } else {
        loadedNewRoutingConfiguration(result);
      }
    } else {
      renderSnackbar('Editor.SavedRoutingConfigurationFailure', 'danger', true);
    }
  };

  return (
    <ToolbarContainer middle marginX="xl">
      <PaddedContainer>
        <InlineEdit
          minWidth={280}
          placeholder={intl.formatMessage({ id: 'Editor.RouteNamePlaceholder' })}
          value={routingConfiguration.routeName}
          onSave={({ value }: { value: string }) => setRouteName(value)}
        />
      </PaddedContainer>
      <FlexItem flex="1">
        <FlexBox middle marginX="s">
          <FlexItemWarning flex="1" style={{ visibility: isDirty ? 'visible' : 'hidden' }}>
            <FlexBox middle marginX="s">
              <IconInformationCircle weight="fill" />
              <span><FormattedMessage id="Editor.UnsavedChanges" /></span>
            </FlexBox>
          </FlexItemWarning>
          <FlexItem style={{ marginRight: '20px' }}>
            <FlexBox middle marginX="s">
              <h6><FormattedMessage id="Editor.Active" /></h6>
              <FlexItem>
                <Toggle size="sm" on onClick={toggleActive} />
              </FlexItem>
            </FlexBox>
          </FlexItem>
          <Button
            type="primary"
            onClick={saveRoute}
            disabled={isSavingRoutingConfiguration || !isDirty}
            data-testid="save-routing-configuration"
          >
            <FlexBox marginX="s">
              <IconFloppyDiskAlt weight="fill" />
              <span>
                <FormattedMessage id={isSavingRoutingConfiguration ? 'Editor.SavingRoute' : 'Editor.SaveRoute'} />
              </span>
            </FlexBox>
          </Button>
        </FlexBox>
      </FlexItem>
    </ToolbarContainer>
  );
};

export default Toolbar;
