import React from 'react';
import { Select } from '@cimpress/react-components';
import CountryRegionData from 'country-region-data/data.json';

import { SelectOption } from '../../../../../types/types';

export type CountrySelectorProps = {
  label: React.ReactNode;
  country?: SelectOption;
  setCountry(country: SelectOption): void;
  // allow pass-through props
  [propName: string]: any;
};

const countries: SelectOption[] = CountryRegionData.map(
  ({ countryName }: { countryName: string }) => ({ label: countryName, value: countryName }),
);

const CountrySelector: React.FC<CountrySelectorProps> = ({
  country, setCountry, label, ...props
}) => (
  <Select
    {...props}
    label={label}
    options={countries}
    value={country}
    onChange={setCountry}
    tether
    styles={{
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
      }),
    }}
  />
);

export default CountrySelector;
