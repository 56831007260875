import styled from 'styled-components';

interface FlexItemProps {
  order?: string;
  flexGrow?: string;
  flexShrink?: string;
  flexBasis?: string;
  flex?: string;
  alignSelf?: string;
}

const FlexItem = styled.div<FlexItemProps>`
  flex-grow: ${({ flexGrow }) => flexGrow || '0'};
  flex-shrink: ${({ flexShrink }) => flexShrink || '1'};
  flex-basis: ${({ flexBasis }) => flexBasis || 'auto'};
  ${({ order }) => (order ? `order: ${order};` : '')}
  ${({ flex }) => (flex ? `flex: ${flex};` : '')}
  ${({ alignSelf }) => (alignSelf ? `align-self: ${alignSelf};` : '')}
`;

export default FlexItem;
