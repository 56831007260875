import styled from 'styled-components';

interface FullHeightContainerProps {
  backgroundColor?: string;
  top?: string;
}

const FullHeightContainer = styled.div<FullHeightContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || ''};
  min-height: calc(-${({ top }) => top || '156px'} + 100vh);
  max-height: calc(-${({ top }) => top || '156px'} + 100vh);
  max-width: 100%;
  overflow: auto;
  overflow-x: hidden;
`;

export default FullHeightContainer;
