import { RoutingConfiguration } from './types/types';

// eslint-disable-next-line import/prefer-default-export
export const routingConfigurations: RoutingConfiguration[] = [
  {
    id: 'bdda3ffc-33c9-4d23-b5f7-6d4f1ee8a586',
    routeName: 'routing configuration 1',
    filters: [],
    createdAt: 'yyyy-MM-ddTHH:mm:ssZ',
    createdBy: 'R',
  },
  {
    id: 'e6ba7899-d57e-4f4a-b419-0fce88317a10',
    routeName: 'routing configuration 2',
    filters: [],
    createdAt: 'yyyy-MM-ddTHH:mm:ssZ',
    createdBy: 'A',
  },
  {
    id: '1e3088d2-1d5b-49a3-a82a-675d615969af',
    routeName: 'routing configuration 3',
    filters: [],
    createdAt: 'yyyy-MM-ddTHH:mm:ssZ',
    createdBy: 'B',
  },
];
