import React, {
  createContext, Dispatch, useContext, useReducer,
} from 'react';

import reducer, {
  Action,
  EditorState,
  SET_ROUTE_NAME,
  TOGGLE_IS_ACTIVE,
  UPDATE_FILTER,
  REMOVE_FILTER,
  REORDER_FILTERS,
  LOADED_NEW_ROUTING_CONFIGURATION,
} from '../reducers/editor';
import { Filter, RoutingConfiguration } from '../types/types';

export type EditorActions = {
  toggleActive(): void;
  setRouteName(value: string): void;
  updateFilter(filter: Filter): void;
  removeFilter(id: string): void;
  reorderFilters(filters: Filter[]): void;
  loadedNewRoutingConfiguration(routingConfiguration: RoutingConfiguration): void;
};

type UseEditor = {
  editorState: EditorState;
  actions: EditorActions;
};

const initialState: EditorState = {
  isDirty: false,
  routingConfiguration: {
    routeName: '',
    filters: [],
  },
};

export const EditorContext = createContext<{
  editorState: EditorState,
  dispatch: Dispatch<Action>;
}>({
  dispatch: () => null,
  editorState: initialState,
});

export const EditorProvider: React.FC = ({ children }) => {
  const [editorState, dispatch] = useReducer(reducer, initialState);
  return (
    <EditorContext.Provider value={{ editorState, dispatch }}>
      {children}
    </EditorContext.Provider>
  );
};

export default (): UseEditor => {
  const { editorState, dispatch } = useContext(EditorContext);

  const toggleActive = () => dispatch({ type: TOGGLE_IS_ACTIVE });
  const setRouteName = (value: string) => dispatch({ type: SET_ROUTE_NAME, value });
  const updateFilter = (filter: Filter) => dispatch({ type: UPDATE_FILTER, value: filter });
  const removeFilter = (id: string) => dispatch({ type: REMOVE_FILTER, value: id });
  const reorderFilters = (filters: Filter[]) => dispatch({ type: REORDER_FILTERS, value: filters });
  const loadedNewRoutingConfiguration = (routingConfiguration: RoutingConfiguration) => {
    dispatch({ type: LOADED_NEW_ROUTING_CONFIGURATION, value: routingConfiguration });
  };

  return {
    editorState,
    actions: {
      toggleActive,
      setRouteName,
      updateFilter,
      removeFilter,
      reorderFilters,
      loadedNewRoutingConfiguration,
    },
  };
};
