import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Header from '@cimpress-technology/react-platform-header';
import SettingsModal from '@cimpress-technology/react-platform-settings';
import styled from 'styled-components';
import { colors } from '@cimpress/react-components';

import auth from './auth';
import { LANGUAGES } from './constants/languages';
import useSettings from './hooks/useSettings';

function login() {
  return auth.login();
}

function logout() {
  return auth.logout('/');
}

const HeaderContainer = styled.div`
  background-color: ${colors.silver};
  position: sticky;
  position: -webkit-sticky;
  z-index: 10;
  top: 0px;
`;

type Props = {
  children: React.ReactNode | React.ReactNode[],
};

const Shell: React.FC<Props> = ({ children }) => {
  const { settings: { language } } = useSettings();

  let envString = '';

  if (process.env.REACT_APP_ENVIRONMENT_NAME) {
    envString += ` - ${process.env.REACT_APP_ENVIRONMENT_NAME}`;
  }

  if (process.env.REACT_APP_GIT_COMMIT) {
    envString += ` (${process.env.REACT_APP_GIT_COMMIT})`;
  }

  const appLinks = [
    {
      id: 'products',
      content: <Link to="/products"><FormattedMessage id="Products.PageTitle" /></Link>,
    },
    {
      id: 'routes',
      content: <Link to="/routes"><FormattedMessage id="Routes.PageTitle" /></Link>,
    },
    {
      id: 'editor',
      content: <Link to="/editor"><FormattedMessage id="Editor.PageTitle" /></Link>,
    },
    {
      id: 'settingsModal',
      content: (
        <SettingsModal
          authToken={auth.getAccessToken()}
          supportedLanguages={LANGUAGES}
          lang={language}
        />
      ),
    },
  ];

  return (
    <div>
      <HeaderContainer>
        <Header
          responsive
          accessToken={auth.getAccessToken()}
          appTitle={<Link to="/"><FormattedMessage id="AppTitle" /></Link>}
          appLinks={appLinks}
          isLoggedIn={auth.isLoggedIn()}
          profile={auth.getProfile()}
          onLogInClicked={login}
          onLogOutClicked={logout}
        />
      </HeaderContainer>
      {children}

      <span style={{ display: 'none' }}>
        Version:
        {envString}
      </span>
    </div>
  );
};

export default Shell;
