import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { keyBy, map, reject } from 'lodash';

import { FlexBox } from '@cimpress/react-components';
import {
  Fulfiller, FulfillerHandler, FulfillersSelection,
} from '../../../types/types';
import FilterConditionsTitleBar from '../FilterConditionsTitleBar';
import SectionWrapper from '../SectionWrapper';
import FilterFulfillers from './FilterFulfillers';
import useFulfillers from '../../../context/FulfillersContext';
import SelectedFulfillers from './SelectedFulfillers';
import { FlexItem } from '../../styled';
import Loading from '../Loading';

const SectionContainer = styled(FlexBox)`
  margin-top: 18px;
`;

type FulfillerSelectorProps = {
  droppableId: string;
  readOnly: boolean;
  fulfillersSelection: FulfillersSelection;
  onChange(fulfillersSelection: FulfillersSelection): void;
};

const FulfillerSelector: React.FC<FulfillerSelectorProps> = ({ droppableId, fulfillersSelection, onChange }) => {
  const { fulfillers, isLoading } = useFulfillers();

  const { fulfillerIds = [] } = fulfillersSelection;

  const changeMode = (isAlternateMode: boolean) => onChange({
    ...fulfillersSelection,
    useFulfillers: !isAlternateMode,
  });

  const removeFulfiller: FulfillerHandler = (fulfillerId) => onChange({
    ...fulfillersSelection,
    fulfillerIds: reject(fulfillerIds, (id) => id === fulfillerId),
  });

  const fulfillerDictionary = keyBy(fulfillers, (fulfiller) => fulfiller.fulfillerId);
  const fulfillerIdSet = new Set(fulfillerIds);
  const isFulfillerSelected = (fulfiller: Fulfiller) => fulfillerIdSet.has(fulfiller.fulfillerId);

  return (
    <SectionWrapper>
      {isLoading
        ? <Loading />
        : (
          <>
            <FilterConditionsTitleBar
              type="THEN"
              message={<FormattedMessage id="Filters.UseFulfillers" />}
              isAlternateMode={fulfillersSelection.useFulfillers === false}
              onModeChange={changeMode}
            />
            <SectionContainer marginX="l">
              <FlexItem flexBasis="50%">
                <FilterFulfillers fulfillers={reject(fulfillers, isFulfillerSelected)} />
              </FlexItem>
              <FlexItem flexBasis="50%">
                <SelectedFulfillers
                  droppableId={droppableId}
                  fulfillers={fulfillers.length
                    ? map(fulfillersSelection.fulfillerIds, (id) => fulfillerDictionary[id])
                    : []}
                  onRemove={removeFulfiller}
                />
              </FlexItem>
            </SectionContainer>
          </>
        )}
    </SectionWrapper>
  );
};

export default FulfillerSelector;
