import { find } from 'lodash';
import { DropResult } from 'react-beautiful-dnd';

import BaseHandler from './BaseHandler';
import { Filter, GeocodeFilterData } from '../../types/types';
import { EditorState } from '../../reducers/editor';
import { EditorActions } from '../../context/EditorContext';
import { parseAggregateDroppableId } from '../droppableIdHelper';

export default class GeocodeFilterHandler extends BaseHandler {
  draggableId: string;

  constructor(
    editorState: EditorState,
    actions: EditorActions,
    dropResult: DropResult,
  ) {
    super(editorState, actions, dropResult);
    this.draggableId = dropResult.draggableId;
  }

  move() {
    if (!this.destination) {
      return;
    }

    const { customString: filterId } = parseAggregateDroppableId(this.destination.droppableId);

    const filter = find(
      this.editorState.routingConfiguration.filters,
      { id: filterId },
    ) as Filter<GeocodeFilterData>;

    if (!filter) {
      throw new Error(`Could not find filter with id ${filterId}`);
    }

    const newFulfillerList = Array.from(filter.filterData.fulfillerIds || []);
    newFulfillerList.splice(this.destination.index, 0, this.draggableId);
    this.actions.updateFilter({
      ...filter,
      filterData: {
        ...filter.filterData,
        fulfillerIds: newFulfillerList,
      },
    });
  }

  reorder = () => { };
}
