import { CssLoader } from '@cimpress/react-components';
import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';

import App from './App';
import * as serviceWorker from './serviceWorker';

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <CssLoader>
      <App />
    </CssLoader>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
