import React, { useState } from 'react';
import { Select, Button, FlexBox } from '@cimpress/react-components';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Filter,
  FilterComponentProps,
  FilterType,
  RoutingFilterRegistry,
} from '../../../../types/types';
import { FlexItem } from '../../../styled';

const SelectWrapper = styled(FlexItem)`
  max-width: 500px;
  min-width: 300px;
  margin-right: 10px;

  .form-group {
    margin-bottom: 0;
  }
`;

type PrimordialOption = {
  value: FilterType;
  label: string;
};

const Primordial: React.FC<FilterComponentProps> = ({ filter, onUpdate }) => {
  const [selectedFilter, setSelectedFilter] = useState<PrimordialOption>();
  const intl = useIntl();
  const { id, filterData: { members } }: { id: string, filterData: { members: RoutingFilterRegistry } } = filter;
  const options = Object.entries(members).map(([filterType, registration]) => ({
    value: filterType,
    label: intl.formatMessage({ id: registration.filterTitleId }),
  } as PrimordialOption));

  const onFilterSelection = () => {
    if (!selectedFilter) {
      return;
    }

    const newFilter: Filter = {
      filterType: selectedFilter.value,
      filterName: '',
      filterData: {},
      id,
    };

    onUpdate(newFilter);
  };

  return (
    <FlexBox shouldWrap>
      <SelectWrapper flexGrow="1">
        <Select
          isClearable
          label={intl.formatMessage({ id: 'Filters.SelectFilter' })}
          value={selectedFilter}
          onChange={setSelectedFilter}
          options={options}
          tether
          required
        />
      </SelectWrapper>
      <Button onClick={onFilterSelection} disabled={!selectedFilter}>
        <FormattedMessage id="Filters.ConfirmFilterSelection" />
      </Button>
    </FlexBox>
  );
};

export default Primordial;
