import { IntlShape } from 'react-intl';

export type SnackbarStyle = 'info' | 'success' | 'warning' | 'danger';

export type RenderSnackbarHandler = (
  intlId: string,
  style: SnackbarStyle,
  shouldManuallyDismiss?: boolean,
) => void;

export type IntlProps = {
  intl: IntlShape;
};

export type RoutingConfiguration = {
  id?: string;
  routeName: string;
  filters: Filter[];
  createdAt?: string;
  createdBy?: string;
  modifiedAt?: string;
};

export type Filter<T = any> = {
  filterType: FilterType;
  filterName: string;
  filterData: T;
  id: string;
};

export type FilterType = 'primordial' | 'geocode' | 'capacity';

export type FilterComponentProps<T = any> = {
  filter: Filter<T>;
  readOnly: boolean;
  onUpdate(newFilter: Filter<T>): void;
};

export type Fulfiller = {
  fulfillerId: string;
  name: string;
};

export enum FilterCategory {
  ORDER_ATTRIBUTE,
  PRODUCT_ATTRIBUTE,
  FULFILLER
}

export type RoutingFilterRegistration = {
  Component: React.ComponentType<FilterComponentProps>;
  filterTitleId: string;
  supportsSummaryView: boolean;
  filterCategory?: FilterCategory;
};

export type SelectOption = {
  value: string;
  label: string;
};

export type Geocode = {
  country?: SelectOption;
  region: SelectOption | null;
};

export type GeocodeFilterData = {
  geocodes: Geocode[];
  useShippingLocale: boolean;
  fulfillerIds: string[];
  useFulfillers: boolean;
};

export type RoutingFilterRegistry = Record<FilterType, RoutingFilterRegistration>;

export type FulfillersSelection = {
  fulfillerIds: string[],
  useFulfillers: boolean,
};

export type FulfillerHandler = (fulfillerId: string) => void;
