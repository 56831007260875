import React, { useEffect } from 'react';
import { FlexBox, colors } from '@cimpress/react-components';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import Toolbar from './Toolbar';
import LeftMenu from './LeftMenu';
import Workspace from './Workspace';
import { FlexItem, PageContainer } from '../../styled';
import useEditor, { EditorProvider } from '../../../context/EditorContext';
import useSnackbar from '../../../context/SnackbarContext';
import DragEventHandlerFactory from '../../../utils/dragEventHandlers/DragEventHandlerFactory';
import { useGetRoutingConfiguration } from '../../../hooks/useRoutingConfigurationApi';
import Loading from '../../shared/Loading';
import { ErrorHandler } from '../../shared/ErrorHandler';

const EditorPageContainer = styled(PageContainer)`
  padding-left: 0px;
  background-color: ${colors.horizon};
`;

const DragDropHandler: React.FC = ({ children }) => {
  const { editorState, actions } = useEditor();
  const { renderSnackbar } = useSnackbar();

  const onDragEnd = (result: DropResult) => {
    try {
      const dragEndHandler = DragEventHandlerFactory.getDragEndHandler(
        editorState,
        actions,
        result,
      );

      if (dragEndHandler) {
        dragEndHandler.processDragEnd();
      }
    } catch (error) {
      console.error(error);
      renderSnackbar('UnknownError', 'danger', true);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {children}
    </DragDropContext>
  );
};

const RoutingConfigurationInjector: React.FC = ({ children }) => {
  const { actions } = useEditor();
  const params = useParams<{ id?: string }>();
  const { routingConfiguration, isLoading } = useGetRoutingConfiguration(params.id);

  useEffect(() => {
    if (routingConfiguration) {
      actions.loadedNewRoutingConfiguration(routingConfiguration);
    }
  }, [routingConfiguration]);

  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};

const Editor: React.FC = () => (
  <ErrorHandler>
    <EditorPageContainer>
      <EditorProvider>
        <RoutingConfigurationInjector>
          <Toolbar />
          <DragDropHandler>
            <FlexBox marginX="xl">
              <LeftMenu />
              <FlexItem flex="1">
                <Workspace />
              </FlexItem>
            </FlexBox>
          </DragDropHandler>
        </RoutingConfigurationInjector>
      </EditorProvider>
    </EditorPageContainer>
  </ErrorHandler>
);

export default Editor;
