import { v4 as uuid } from 'uuid';

import BaseHandler from './BaseHandler';
import { LEFT_MENU_ITEMS } from '../../components/pages/editor/LeftMenu';
import { FILTER_CATEGORIES } from '../../constants/droppables';
import { Filter } from '../../types/types';

export default class WorkspaceFiltersHandler extends BaseHandler {
  move() {
    if (!this.destination) {
      return;
    }

    if (this.source.droppableId === FILTER_CATEGORIES) {
      const sourceClone = Array.from(LEFT_MENU_ITEMS);
      const newFilterList: Filter[] = Array.from(this.editorState.routingConfiguration.filters);
      const item: Filter = sourceClone[this.source.index];

      newFilterList.splice(this.destination.index, 0, { ...item, id: uuid() });

      this.actions.reorderFilters(newFilterList);
    }
  }

  reorder() {
    if (!this.destination) {
      return;
    }

    const newFilterList = Array.from(this.editorState.routingConfiguration.filters);
    const [movedFilter] = newFilterList.splice(this.source.index, 1);

    newFilterList.splice(this.destination.index, 0, movedFilter);

    this.actions.reorderFilters(newFilterList);
  }
}
